/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowRightSLine, 
  RiArrowLeftSLine, 
  RiFileTextLine, 
  RiTimeLine, 
  RiCheckboxCircleLine, 
  RiTeamLine, 
  RiSettings4Line,
  RiCalendarCheckLine,
  RiShieldCheckLine,
  RiRocketLine,
  RiUserStarLine,
  RiGlobalLine,
  RiCalendarLine,
  RiMailLine
} from "react-icons/ri"

const CustomPolicyDevelopmentPage = () => {
  return (
    <Layout>
      <SEO 
        title="Custom HR Policy Development Services | Tailored Workplace Documentation"
        description="Expert HR policy development services customized to your organization's unique needs, industry requirements, and company culture. Get policies that drive compliance and performance."
        keywords={[
          "custom HR policy development",
          "tailored workplace policies",
          "HR policy consulting",
          "policy compliance services",
          "industry-specific HR policies",
          "regulatory compliance documentation",
          "culture-aligned HR policies"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5, 6],
        pb: [4, 5, 6],
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          position: 'relative',
          zIndex: 2
        }}>
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '6fr 5fr'],
            gap: [4, 5],
            alignItems: 'center'
          }}>
            <div>
              <h1 sx={{
                fontSize: ['2rem', '2.5rem', '3rem'],
                fontWeight: 800,
                lineHeight: 1.1,
                mb: 3,
                color: 'primary',
                letterSpacing: '-0.01em'
              }}>
                Custom HR Policy Development Tailored to Your Organization
              </h1>
              
              <p sx={{
                fontSize: ['1.1rem', '1.25rem'],
                lineHeight: 1.4,
                mb: 4,
                color: 'text',
                maxWidth: '600px'
              }}>
                Beyond templates, we create bespoke policies that reflect your organization's 
                unique needs, industry requirements, and company culture while ensuring 
                full regulatory compliance.
              </p>

              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Schedule a Consultation <RiArrowRightSLine />
                </Link>
                
                <Link to="#our-framework" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Explore Our Framework <RiArrowRightSLine />
                </Link>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              transform: ['scale(1)', 'scale(1)', 'scale(1.02)'],
              display: ['none', 'none', 'block']
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}>
                  <div sx={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    bg: 'primaryMuted',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'primary',
                    fontSize: '1.5rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <div>
                    <h3 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>Industry-Specific Compliance</h3>
                    <p sx={{
                      fontSize: '0.95rem',
                      color: 'textMuted',
                      mb: 0
                    }}>Policies aligned with your sector's regulations</p>
                  </div>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}>
                  <div sx={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    bg: 'primaryMuted',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'primary',
                    fontSize: '1.5rem'
                  }}>
                    <RiTeamLine />
                  </div>
                  <div>
                    <h3 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>Culture-Aligned Design</h3>
                    <p sx={{
                      fontSize: '0.95rem',
                      color: 'textMuted',
                      mb: 0
                    }}>Reflects your values and organizational culture</p>
                  </div>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}>
                  <div sx={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    bg: 'primaryMuted',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'primary',
                    fontSize: '1.5rem'
                  }}>
                    <RiSettings4Line />
                  </div>
                  <div>
                    <h3 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>Scalable Implementation</h3>
                    <p sx={{
                      fontSize: '0.95rem',
                      color: 'textMuted',
                      mb: 0
                    }}>Right-sized for your organization's growth</p>
                  </div>
                </div>
              </div>
              
              <div sx={{
                mt: 4,
                pt: 3,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)',
                textAlign: 'center'
              }}>
                <p sx={{
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  color: 'primary',
                  mb: 0
                }}>
                  Join 200+ organizations with custom policies
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Why Custom Policies Section */}
      <section sx={{
        py: [4, 5],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Why Organizations Choose Custom Policy Development
            </h2>
            
            <p sx={{
              fontSize: '1.1rem',
              maxWidth: '800px',
              mx: 'auto',
              color: 'text'
            }}>
              Generic templates often fall short in addressing the specific challenges and opportunities 
              of your unique organization. Our custom approach delivers significant advantages:
            </p>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2rem',
                mb: 3
              }}>
                <RiGlobalLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Industry-Specific Compliance
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                flex: 1
              }}>
                Tailored policies that address the specific regulatory requirements of your industry, 
                from healthcare to finance to manufacturing and beyond.
              </p>
              
              <div sx={{
                mt: 3,
                pt: 3,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)'
              }}>
                <p sx={{
                  fontSize: '0.9rem',
                  fontStyle: 'italic',
                  color: 'textMuted',
                  mb: 0
                }}>
                  "Their healthcare-specific policies saved us countless hours navigating HIPAA compliance." — Medical Director
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2rem',
                mb: 3
              }}>
                <RiUserStarLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Culture Reinforcement
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                flex: 1
              }}>
                Policies that align with and reinforce your organizational values, helping to strengthen 
                your culture while maintaining necessary compliance standards.
              </p>
              
              <div sx={{
                mt: 3,
                pt: 3,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)'
              }}>
                <p sx={{
                  fontSize: '0.9rem',
                  fontStyle: 'italic',
                  color: 'textMuted',
                  mb: 0
                }}>
                  "Our flexible work policy now truly reflects our culture of trust and autonomy." — HR Director
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '2rem',
                mb: 3
              }}>
                <RiRocketLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2,
                color: 'text'
              }}>
                Operational Efficiency
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                flex: 1
              }}>
                Streamlined policies that work with your existing processes, not against them, 
                reducing friction and supporting productivity across your organization.
              </p>
              
              <div sx={{
                mt: 3,
                pt: 3,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)'
              }}>
                <p sx={{
                  fontSize: '0.9rem',
                  fontStyle: 'italic',
                  color: 'textMuted',
                  mb: 0
                }}>
                  "Our approval workflows now take 60% less time while maintaining compliance." — Operations Manager
                </p>
              </div>
            </div>
          </div>
          
          <div sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              maxWidth: '800px',
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              flexWrap: ['wrap', 'nowrap']
            }}>
              <div sx={{ flex: 1 }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'primary'
                }}>
                  Ready to transform your HR policies?
                </h3>
                
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  mb: 0
                }}>
                  Schedule a no-obligation consultation with our policy experts to discuss your needs.
                </p>
              </div>
              
              <div>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Get Started <RiArrowRightSLine />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Our Framework Section */}
      <section id="our-framework" sx={{
        py: [4, 5],
        bg: '#f8f9fa'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Our Policy Development Framework
            </h2>
            
            <p sx={{
              fontSize: '1.1rem',
              maxWidth: '800px',
              mx: 'auto',
              color: 'text'
            }}>
              A structured, proven approach to creating policies that drive compliance, 
              performance, and positive culture.
            </p>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            {/* Step 1 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  1
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Discovery & Assessment
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                We conduct a comprehensive assessment of your organization's current policies, 
                regulatory requirements, and cultural values.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Stakeholder interviews</li>
                <li>• Policy gap analysis</li>
                <li>• Regulatory requirements</li>
                <li>• Cultural assessment</li>
              </ul>
            </div>
            
            {/* Step 2 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  2
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Custom Policy Design
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                Our HR policy experts create tailored policies aligned with your regulatory 
                requirements and organizational culture.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Industry-specific compliance</li>
                <li>• Clear, accessible language</li>
                <li>• Culture-aligned provisions</li>
                <li>• Process integration</li>
              </ul>
            </div>
            
            {/* Step 3 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  3
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Stakeholder Review
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                We facilitate a collaborative review process with key stakeholders to ensure 
                the policies meet organizational needs.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Leadership validation</li>
                <li>• Department feedback</li>
                <li>• Legal review coordination</li>
                <li>• Policy refinement</li>
              </ul>
            </div>
            
            {/* Step 4 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  4
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Implementation Planning
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                We develop a comprehensive rollout strategy that ensures smooth adoption and 
                minimizes operational disruption.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Communication strategy</li>
                <li>• Training development</li>
                <li>• Implementation timeline</li>
                <li>• Manager enablement tools</li>
              </ul>
            </div>
            
            {/* Step 5 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  5
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Rollout & Training
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                We support implementation with targeted communication and training 
                to ensure understanding across the organization.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Employee training</li>
                <li>• Communication materials</li>
                <li>• Digital distribution</li>
                <li>• FAQ development</li>
              </ul>
            </div>
            
            {/* Step 6 */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              borderTop: '4px solid',
              borderColor: 'primary',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mb: 3
              }}>
                <div sx={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  bg: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '2rem',
                  fontWeight: '700',
                  mb: 3
                }}>
                  6
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'primary',
                  mb: 0
                }}>
                  Monitoring & Refinement
                </h3>
              </div>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.5,
                textAlign: 'center'
              }}>
                We establish ongoing review mechanisms to ensure policies remain effective 
                and compliant with evolving regulations.
              </p>
              
              <ul sx={{
                listStyle: 'none',
                pl: 0,
                mb: 0,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mt: 'auto',
                textAlign: 'center'
              }}>
                <li>• Effectiveness metrics</li>
                <li>• Regulatory monitoring</li>
                <li>• Scheduled review process</li>
                <li>• Continuous improvement</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      {/* Testimonials Section */}
      <section sx={{
        py: [4, 5],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              What Our Clients Say
            </h2>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            <div sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)'
            }}>
              <p sx={{
                fontSize: '0.95rem',
                fontStyle: 'italic',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                "Their custom policy development process transformed our outdated handbook into a strategic 
                asset that both ensures compliance and reinforces our company culture. The industry-specific 
                expertise they brought was invaluable."
              </p>
              
              <div sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3
              }}>
                <div sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  bg: 'primary',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.1rem',
                  fontWeight: 'bold'
                }}>
                  JM
                </div>
                
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    mb: 0,
                    color: 'text'
                  }}>
                    Jennifer Miller
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.85rem',
                    color: 'textMuted',
                    mb: 0
                  }}>
                    VP of HR, Healthcare Services
                  </p>
                </div>
              </div>
            </div>
            
            <div sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)'
            }}>
              <p sx={{
                fontSize: '0.95rem',
                fontStyle: 'italic',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                "As a rapidly scaling tech company, we needed policies that would grow with us. Their team 
                delivered a framework that's both flexible and compliant, saving us countless hours of 
                research and revision. Implementation was seamless."
              </p>
              
              <div sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3
              }}>
                <div sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  bg: 'primary',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.1rem',
                  fontWeight: 'bold'
                }}>
                  DT
                </div>
                
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    mb: 0,
                    color: 'text'
                  }}>
                    David Torres
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.85rem',
                    color: 'textMuted',
                    mb: 0
                  }}>
                    COO, Tech Startup
                  </p>
                </div>
              </div>
            </div>
            
            <div sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)'
            }}>
              <p sx={{
                fontSize: '0.95rem',
                fontStyle: 'italic',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                "Operating in multiple states created a compliance nightmare for our HR team. Their 
                consultants developed a comprehensive policy structure that accounts for all jurisdictions 
                while remaining clear and accessible to our employees."
              </p>
              
              <div sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3
              }}>
                <div sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  bg: 'primary',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.1rem',
                  fontWeight: 'bold'
                }}>
                  SJ
                </div>
                
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    mb: 0,
                    color: 'text'
                  }}>
                    Sarah Johnson
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.85rem',
                    color: 'textMuted',
                    mb: 0
                  }}>
                    HR Director, Retail Chain
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Policy Types Section */}
      <section sx={{
        py: [4, 5],
        bg: '#f8f9fa'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Custom Policy Types We Develop
            </h2>
            
            <p sx={{
              fontSize: '1.1rem',
              maxWidth: '800px',
              mx: 'auto',
              color: 'text'
            }}>
              From comprehensive employee handbooks to specific policy areas, we tailor each 
              document to your organization's unique requirements.
            </p>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '1.75rem',
                mb: 2
              }}>
                <RiFileTextLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                mb: 1,
                color: 'text'
              }}>
                Employee Handbooks
              </h3>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                lineHeight: 1.5,
                mb: 0
              }}>
                Comprehensive guides tailored to your culture and compliance needs.
              </p>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '1.75rem',
                mb: 2
              }}>
                <RiTimeLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                mb: 1,
                color: 'text'
              }}>
                Leave & Time Off
              </h3>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                lineHeight: 1.5,
                mb: 0
              }}>
                Balanced policies that support well-being and business continuity.
              </p>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '1.75rem',
                mb: 2
              }}>
                <RiUserStarLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                mb: 1,
                color: 'text'
              }}>
                Performance Management
              </h3>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                lineHeight: 1.5,
                mb: 0
              }}>
                Review and feedback frameworks that drive growth and accountability.
              </p>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)'
              }
            }}>
              <div sx={{
                color: 'primary',
                fontSize: '1.75rem',
                mb: 2
              }}>
                <RiShieldCheckLine />
              </div>
              
              <h3 sx={{
                fontSize: '1.1rem',
                fontWeight: 600,
                mb: 1,
                color: 'text'
              }}>
                Workplace Safety
              </h3>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                lineHeight: 1.5,
                mb: 0
              }}>
                Industry-specific safety protocols and emergency procedures.
              </p>
            </div>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
            gap: 4
          }}>
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary'
              }}>
                Industry-Specific Expertise
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 0
              }}>
                Our consultants bring specialized knowledge in various industries, including:
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Healthcare</span>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Technology</span>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Manufacturing</span>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Financial Services</span>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Retail</span>
                </div>
                
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.1rem'
                  }}>
                    <RiCheckboxCircleLine />
                  </div>
                  <span sx={{ fontSize: '0.9rem' }}>Non-profit</span>
                </div>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.06)',
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary'
              }}>
                Our Commitment to Excellence
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 0
              }}>
                Every policy we develop reflects our commitment to:
              </p>
              
              <ul sx={{
                pl: 4,
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 0
              }}>
                <li>Regulatory compliance across all applicable jurisdictions</li>
                <li>Clear, accessible language for all employees</li>
                <li>Alignment with organizational values and culture</li>
                <li>Practical implementation with minimal disruption</li>
                <li>Scalability to accommodate organizational growth</li>
                <li>Ongoing support and updates as regulations evolve</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <section sx={{
        py: [4, 5],
        bg: '#F4F5F7',
        color: 'text'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3,
          textAlign: 'center'
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            mb: 3,
            color: 'primary'
          }}>
            Ready to Transform Your HR Policies?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            mb: 4,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Schedule a consultation with our policy development experts to discuss your 
            organization's unique needs and learn how we can help you create policies 
            that drive compliance, performance, and positive culture.
          </p>

          <div sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                bg: 'primary',
                opacity: 0.9,
                color: 'white'
              }
            }}>
              Schedule a Demo
              <RiCalendarLine />
            </Link>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'transparent',
              color: 'primary',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              border: '2px solid',
              borderColor: 'primary',
              transition: 'transform 0.2s ease, background-color 0.2s ease',
              '&:hover': {
                bg: 'transparent',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }
            }}>
              Contact Sales
              <RiMailLine />
            </Link>
          </div>
        </div>
      </section>
      
      {/* Navigation Links */}
      <section sx={{
        py: 4,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '1px solid',
          borderColor: 'rgba(0,0,0,0.1)',
          pt: 4
        }}>
          <Link to="/" sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 2,
            color: 'primary',
            textDecoration: 'none',
            fontSize: '0.95rem',
            fontWeight: 600
          }}>
            <RiArrowLeftSLine /> Main Page
          </Link>
          
          <Link to="/policies" sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 2,
            color: 'primary',
            textDecoration: 'none',
            fontSize: '0.95rem',
            fontWeight: 600
          }}>
            Policy Templates <RiArrowRightSLine />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default CustomPolicyDevelopmentPage 